import "../../utilites/calendar.css";
import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker, { registerLocale } from "react-datepicker";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import gb from "date-fns/locale/en-GB";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import "react-datepicker/dist/react-datepicker.css";
import { useGetUserList } from "../../hooks/useReport";
import moment from "moment";
registerLocale("gb", gb);

const DownloadReport = () => {
  const { data: allUsers, isLoading: isAllUsersLoading } = useGetUserList();

  // console.log("users", allUsers);
  const currentDate = new Date();
  const initialDate = currentDate.setDate(currentDate.getDate());
  const today = new Date(initialDate);
  const currentYear = new Date().getFullYear();
  const range = (start, end) => {
    return new Array(end - start).fill(null).map((d, i) => i + start);
  };
  const years = range(currentYear, getYear(new Date()) + 30);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div className="new-frm-sec-start">
      <div className="container">
        <h1>Download Report</h1>
        <div className="new-frm-box">
          <Formik
            enableReinitialize={false}
            initialValues={{
              department: "testing",
              user: "",
              fromDate: "",
              toDate: "",
            }}
            validationSchema={Yup.object({
              fromDate: Yup.string().required("From Date is required"),
              toDate: Yup.string().required("To Date is required"),
              department: Yup.string().required("Department is required"),
            })}
            onSubmit={async (values) => {
              // console.log("values", values);
              window.open(
                `https://metrics-liard.vercel.app/v1/${
                  values.department || "testing"
                }/excel-download?uid=${values.user || 0}&date_from=${moment(
                  values.fromDate
                ).format("YYYY-MM-DD")}&date_to=${moment(values.toDate).format(
                  "YYYY-MM-DD"
                )}`
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form autoComplete="off" id="transferLandingOneWay">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Department</label>
                      <div className="all-frm-box frm_box_wrap">
                        <select
                          className="form-control"
                          onChange={handleChange}
                          name="department"
                          value={values.department}
                          onBlur={handleBlur}
                        >
                          {/* <option>Shipping</option>
                          <option>Cleaning</option>
                          <option>Repairing</option>
                          <option>Photography</option> */}
                          <option value={"screening"}>Screening</option>
                          {/* <option>Shelving</option> */}
                          <option value={"testing"}>Testing</option>
                        </select>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                      {touched.department && errors.department ? (
                        <span style={{ color: "red" }}>
                          {errors.department}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>User</label>
                      <div className="all-frm-box frm_box_wrap">
                        <select
                          className="form-control"
                          onChange={handleChange}
                          name="user"
                          value={values.user}
                          onBlur={handleBlur}
                        >
                          <option value={""}>Select User</option>
                          {allUsers?.users?.map((user, i) => {
                            return (
                              <option value={user?.id} key={i}>
                                {user?.firstname + " " + user?.lastname}
                              </option>
                            );
                          })}
                          <option>User2</option>
                        </select>
                        <i className="fa-solid fa-chevron-down"></i>
                      </div>
                      {/* {touched.user && errors.user ? (
                        <span style={{ color: "red" }}>{errors.user}</span>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>From</label>
                      <div className="all-frm-box frm_box_wrap">
                        <DatePicker
                          className="form-control"
                          calendarStartDay={1}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div className="datepicker-custom-bx">
                              <div className="datepicker-custom-header">
                                <button
                                  type="button"
                                  className="cstm-date-btn1"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <IoIosArrowDropleft
                                    className={`datePickerArrowIcon`}
                                  />
                                </button>
                                <div className="cstm-select-start">
                                  <Field
                                    as="select"
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                    className="cstm-date-select"
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Field>

                                  <Field
                                    as="select"
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(Number(value))
                                    }
                                    className="cstm-date-select"
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                                <button
                                  type="button"
                                  className="cstm-date-btn2"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <IoIosArrowDropright
                                    className={`datePickerArrowIcon`}
                                  />
                                </button>
                              </div>
                            </div>
                          )}
                          calendarClassName="rastaStripesCalendat"
                          id="fromDate"
                          name="fromDate"
                          placeholderText="From"
                          selected={values.fromDate}
                          onChange={(date) => {
                            if (values.toDate !== "") {
                              const date1 = new Date(values.toDate);
                              const date2 = new Date(date);
                              if (date1.getTime() < date2.getTime()) {
                                setFieldValue("toDate", "");
                              }
                            }

                            setFieldValue("fromDate", date);
                          }}
                          // onSelect={handleChange}
                          onBlur={handleBlur}
                          locale="gb"
                          maxDate={today}
                          showDisabledMonthNavigation
                          dateFormat="EEE d MMM yyyy"
                          value={values.fromDate}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          onFocus={(e) => e.target.blur()}
                        />
                        <i className="fa-solid fa-calendar-days" />
                      </div>
                      {touched.fromDate && errors.fromDate ? (
                        <span style={{ color: "red" }}>{errors.fromDate}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>To</label>
                      <div className="all-frm-box frm_box_wrap">
                        <DatePicker
                          // disabled={!values.fromDate}
                          className="form-control"
                          calendarStartDay={1}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div className="datepicker-custom-bx">
                              <div className="datepicker-custom-header">
                                <button
                                  type="button"
                                  className="cstm-date-btn1"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  <IoIosArrowDropleft
                                    className={`datePickerArrowIcon`}
                                  />
                                </button>
                                <div className="cstm-select-start">
                                  <Field
                                    as="select"
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                    className="cstm-date-select"
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Field>

                                  <Field
                                    as="select"
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(Number(value))
                                    }
                                    className="cstm-date-select"
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                                <button
                                  type="button"
                                  className="cstm-date-btn2"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  <IoIosArrowDropright
                                    className={`datePickerArrowIcon`}
                                  />
                                </button>
                              </div>
                            </div>
                          )}
                          calendarClassName="rastaStripesCalendat"
                          id="toDate"
                          name="toDate"
                          placeholderText="To"
                          selected={values.toDate}
                          onChange={(date) => setFieldValue("toDate", date)}
                          // onSelect={handleChange}
                          onBlur={handleBlur}
                          locale="gb"
                          minDate={values.fromDate}
                          maxDate={today}
                          showDisabledMonthNavigation
                          dateFormat="EEE d MMM yyyy"
                          value={values.toDate}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          onFocus={(e) => e.target.blur()}
                        />
                        <i className="fa-solid fa-calendar-days"></i>
                      </div>
                      {touched.toDate && errors.toDate ? (
                        <span style={{ color: "red" }}>{errors.toDate}</span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="newfrm-btn-bx">
                  <button
                    type="btn"
                    className="btn-new-btn downloadreport_btn"
                    onClick={handleSubmit}
                  >
                    Download
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default DownloadReport;
