import React from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactApexChart from "react-apexcharts";
const DonutsCard = (props) => {
  // const chartData = {
  //   series: props?.user ? [props?.user?.count] : 600,

  //   options: {
  //     chart: { type: "donut", foreColor: "#ffffff", height: 412 },
  //     legend: { show: false },
  //     dataLabels: { enabled: false },
  //     tooltip: { enabled: false },
  //     fill: { colors: ["#e8eb34", "#ff5156"] },
  //     states: {
  //       hover: { filter: { type: "none", value: 0 } },
  //       active: { filter: { type: "none", value: 0 } },
  //     },
  //     stroke: { width: 0 },

  //     plotOptions: {
  //       pie: {
  //         expandOnClick: false,
  //         donut: {
  //           size: "60%",
  //           labels: {
  //             show: true,
  //             name: { show: false },
  //             total: {
  //               show: true,
  //               showAlways: true,

  //               formatter: function (w) {
  //                 // let sum = 0;

  //                 // for (let i = 0; i < w.globals.seriesTotals.length; i++) {
  //                 //   sum += w.globals.seriesTotals[i];
  //                 // }
  //                 // //   console.log('%c Sum: ' + sum, 'color: green; font-weight: bold;');

  //                 return w.globals.seriesTotals[0] || 0;
  //                 // return sum
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  // };
  const chartData = {
    series: props?.user ? [props?.user?.count] : 600,

    options: {
      chart: { type: "donut", foreColor: "#ffffff", height: 412 },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: { colors: [props?.user?.colorSelector || "#7FD320"] },
      states: {
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },

      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,

                formatter: function (w) {
                  // let sum = 0;

                  // for (let i = 0; i < w.globals.seriesTotals.length; i++) {
                  //   sum += w.globals.seriesTotals[i];
                  // }
                  // //   console.log('%c Sum: ' + sum, 'color: green; font-weight: bold;');

                  return w.globals.seriesTotals[0] || 0;
                  // return sum
                },
              },
            },
          },
        },
      },
    },
  };

  return (
    <>
      <Card className="endingStyle">
        {props.middle}
        {/* <Container> */}
        <Row style={{ minWidth: "100%" }}>
          {props?.user ? (
            <h5 style={{ textAlign: "left", paddingBottom: "10px" }}>
              {(props?.user?.firstName || "John") +
                " " +
                (props?.user?.lastName || "Doe")}
            </h5>
          ) : (
            <h5>John Doe</h5>
          )}
          <Col lg={6}>
            <p style={{ fontSize: 14 }}>{props.left}</p>
            <p className="green" style={{ fontSize: "24px" }}>
              {props?.user ? (
                props?.user?.average
              ) : (
                <p style={{ fontSize: "40px" }}>{props?.shippingData?.score}</p>
              )}
            </p>
          </Col>
          {/* <Col lg={4}>
            <p
              className="green"
              style={{ fontSize: "30px", marginBottom: "30px" }}
            >
              {props?.orderData ? (
                <h1>{props?.orderData.order}</h1>
              ) : (
                props?.shippingData.chart
              )}
            </p>
          </Col> */}
          <Col lg={6}>
            <p style={{ fontSize: 14 }}>{props.last}</p>
            <p className="green" style={{ fontSize: "24px" }}>
              {
                props?.user
                  ? props?.user?.highestCount
                  : // <p style={{ fontSize: "40px" }}>
                    props?.shippingData?.average || 55
                // </p>
              }
            </p>
          </Col>
          <Col lg={12}>
            <h6 style={{ paddingTop: "15px" }}>Today</h6>
            <ReactApexChart
              options={chartData.options}
              // series={[props?.user?.count, props?.user?.count >= 100 ? 0 : 100]} // for multiple partition on donuts
              series={props?.user?.count ? [props?.user?.count] : [600]}
              type="donut"
              height={"100%"}
              // width={"100%"}
            />
          </Col>
        </Row>
        {/* </Container> */}
      </Card>
    </>
  );
};
// checked

export default DonutsCard;
