import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const OrderChart = (props) => {
  const { orderData } = props;

  return (
    <Card className="dataStyle header1">
      Order Data
      <Row>
        <Col style={{ paddingTop: "18px" }}>
          {orderData.map((value, index) => (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className="header">{value.item}</p>
              <div>
                <span className="header">{value.val}</span> {value.val1}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Card>
  );
};

export default OrderChart;
// checked
