import React from "react";
import EndingDueValues from "./sub-components/EndingDueValues";
import { Card, Col, Container, Row } from "react-bootstrap";

const DueCleaningCards = () => {
  const shippedValue = {
    due_today: "168",
    overdue: "34",
    due_tomorrow: "86",
    freight: "8",
  };

  return (
    <div>
      <EndingDueValues value={shippedValue} />
      <Card className="endingPayment">
        <Container>
          <Row>
            <Col style={{ paddingTop: "18px" }}>
              <p style={{ textAlign: "center", color: "white" }}>
                Items Shelved{" "}
              </p>
            </Col>
            <Col style={{ paddingTop: "15px" }}>
              <p style={{ textAlign: "center", color: "white" }}>
                {" "}
                Items Picked{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  );
};

export default DueCleaningCards;
// checked
