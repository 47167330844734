import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import DonutsCard from "./sub-components/donutCards";
import { useNavigate } from "react-router-dom";
import AllEmployeeListPhotography from "./AllEmployeeListPhotography";

const ToPhotography = () => {
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(false);
  const [lastPageVal, setLastPageVal] = useState("");

  const orderData = {
    frieght: "8",

    ground: "144",
  };

  return (
    <>
      {showTable ? (
        <>
          {" "}
          <AllEmployeeListPhotography setShowTable={setShowTable} />
        </>
      ) : (
        <>
          <p className="secondHeader">Photography</p>
          <Row>
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <Col
                xl={4}
                xxl={4}
                lg={4}
                md={6}
                xs={12}
                sm={12}
                key={i}
                onClick={() => {
                  navigate("/photography/details-view");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <DonutsCard
                  left={"Frieght"}
                  last={"Ground"}
                  orderData={orderData}
                  user={[]}
                  setLastPageVal={setLastPageVal}
                />
              </Col>
            ))}
          </Row>

          <p
            className="secondHeader"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              // navigate("/shipping/employee-list");
              setShowTable(!showTable);
            }}
          >
            Show more
          </p>
        </>
      )}
    </>
  );
};

export default ToPhotography;
// checked
