import React, { useState } from "react";
import Ebay from "../../component/shipping/Ebay";
import Shipped from "../../component/shipping/Shipped";
import ToShip from "../../component/shipping/ToShip";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { useGetTestingList } from "../../hooks/useShipping";
import {
  useGetShippingDueList,
  useGetShippingEndingList,
  useGetShippingOrderList,
  useGetShippingPickedShelvingList,
  useGetAllShippingList,
} from "../../hooks/useShipping";
const UserDashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: dueData, isLoading: isDueDataLoading } = useGetShippingDueList({
    query: "orderage",
  });

  const { data: endingData, isLoading: isEndingDataLoading } =
    useGetShippingEndingList({
      query: "listing",
    });

  const { data: orderData, isLoading: isOrderDataLoading } =
    useGetShippingOrderList({
      query: "order",
    });

  const { data: pickedData, isLoading: isPickedDataLoading } =
    useGetShippingPickedShelvingList({
      query: "picked",
    });

  const { data: allShippingList, isLoading: isShippingLoading } =
    useGetAllShippingList({
      limit: currentPage,
    });

  return (
    <>
      <Container fluid>
        <Row lg={12}>
          <Col lg={3}>
            <Ebay
              dueData={dueData}
              isDueDataLoading={isDueDataLoading}
              endingData={endingData}
              isEndingDataLoading={isEndingDataLoading}
              orderData={orderData}
              isOrderDataLoading={isOrderDataLoading}
              pickedData={pickedData}
              isPickedDataLoading={isPickedDataLoading}
            />
          </Col>
          <Col lg={6}>
            <ToShip
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              allShippingList={allShippingList}
              isShippingLoading={isShippingLoading}
            />
          </Col>
          <Col lg={3}>
            <Shipped
              allShippingList={allShippingList}
              isShippingLoading={isShippingLoading}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserDashboard;
