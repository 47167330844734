import React from "react";
import Card from "react-bootstrap/Card";
import { Chart } from "react-google-charts";

function LineChart(props) {
  // const data = [
  //   ["Year", "Sales", "Expenses", "Profit", "Loss"],
  //   ["2004", 1000, 400, 300, 1000],
  //   ["2005", 1170, 460, 879, 800],
  //   ["2006", 660, 1120, 777, 820],
  //   ["2007", 1030, 540, 666, 700],
  // ];

  // const options = {
  //   curveType: "function",
  //   legend: "none",
  //   backgroundColor: "#2a2f33",
  //   hAxis: {
  //     textStyle: { color: "#FFF" },
  //   },
  //   vAxis: {
  //     textStyle: { color: "#FFF" },
  //   },
  // };

  return (
    <Card className="dataStyle header">
      Trends
      <Chart
        chartType="LineChart"
        width="100%"
        height="200px"
        // data={data}
        data={props.data}
        options={props.options}
        // options={options}
      />
    </Card>
  );
}

export default LineChart;
// checked
