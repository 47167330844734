import React from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DueCardValues = ({ dueData }) => {
  return (
    <Card className="endingPayment">
      <Container style={{ paddingRight: "18px" }}>
        <Row>
          <Col style={{ paddingTop: "8px" }}>
            <p
              style={{ textAlign: "center", color: "yellow", fontSize: "14px" }}
            >
              {" "}
              Due Today{" "}
            </p>
            <p className="green" style={{ fontSize: "24px" }}>
              {dueData?.orderages?.filter(
                ({ orderage, cnt }) => orderage === "3"
              )?.[0]?.cnt || 0}
            </p>
          </Col>
          <Col style={{ paddingTop: "15px" }}>
            <p style={{ textAlign: "center", color: "red", fontSize: "14px" }}>
              {" "}
              Overdue{" "}
            </p>
            <p className="red" style={{ fontSize: "24px" }}>
              {dueData?.orderages
                ?.filter((item) => Number(item?.orderage) > 3)
                .reduce((sum, item) => sum + Number(item?.cnt), 0) || 0}
            </p>
          </Col>
          <Col style={{ paddingTop: "8px" }}>
            <p
              style={{
                textAlign: "center",
                color: "yellowgreen",
                fontSize: "14px",
              }}
            >
              {" "}
              Due Tomorrow{" "}
            </p>
            <p className="green" style={{ fontSize: "24px" }}>
              {dueData?.orderages?.filter(
                ({ orderage, cnt }) => orderage === "2"
              )?.[0]?.cnt || 0}
            </p>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default DueCardValues;
