import React from "react";
import Card from "react-bootstrap/Card";
import { Chart } from "react-google-charts";

function LineChart(props) {
  return (
    <Card className="dataStyle header">
      Trends
      <Chart
        chartType="LineChart"
        width="100%"
        height="200px"
        data={props.data}
        options={props.options}
      />
    </Card>
  );
}

export default LineChart;
