import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
const tableData = [
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    action: "eBay",
  },
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    action: "eBay",
  },
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    action: "eBay",
  },
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    action: "eBay",
  },
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    action: "eBay",
  },
];
const DetailsPhotographyView = () => {
  const chartData = {
    series: [600],

    options: {
      chart: { type: "donut", foreColor: "#ffffff", height: 300, width: 300 },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: { colors: ["#7FD320"] },
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,
                formatter: function (w) {
                  //   const totals = w.globals.seriesTotals;

                  //   const result = totals.reduce((a, b) => a + b, 0);

                  //   return (result / 1000).toFixed(3);
                  return w.globals.seriesTotals;
                },
              },
            },
          },
        },
      },
    },
  };
  return (
    <>
      <Container fluid>
        <p className="secondHeader">Photography</p>

        <Row lg={8} className="justify-content-center">
          <Col lg={3}>
            <Card className="endingStyle">
              <Container>
                {/* <div style={{ position: "relative" }}>
                <span
                  className="header"
                  style={{
                    position: "absolute",
                    display: "inline-block",
                    left: "15px",
                  }}
                >
                  Todaykjkj
                </span>
              </div> */}
                <Row>
                  <Col style={{ paddingTop: "38px" }}>
                    <p style={{ textAlign: "center" }}>Today </p>

                    <p>
                      Assets:{" "}
                      <span
                        style={{
                          display: "inline-block",
                          color: "yellowgreen",
                        }}
                      >
                        22
                      </span>
                    </p>

                    <p>
                      Total Quantity:{" "}
                      <span
                        style={{
                          display: "inline-block",
                          color: "yellowgreen",
                        }}
                      >
                        45
                      </span>
                    </p>

                    <p style={{ textAlign: "center", color: "yellow" }}>
                      Action
                      <p style={{ color: "white" }}>
                        eBay{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          :22
                        </span>
                      </p>
                    </p>

                    <p>
                      POS
                      <span
                        style={{
                          display: "inline-block",
                          color: "yellowgreen",
                        }}
                      >
                        :0
                      </span>
                    </p>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="endingStyle">
              <h4>Keely Peffer</h4>
              <Container>
                <Card.Body>
                  <Card.Title>Today</Card.Title>
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="donut"
                    height={300}
                  />
                </Card.Body>
              </Container>
            </Card>
          </Col>
          <Col lg={3}>
            {" "}
            <Card className="endingStyle">
              <Container>
                <Row>
                  <Col>
                    Daily Average
                    <p
                      className="green"
                      style={{ fontSize: "26px", paddingLeft: "58px" }}
                    >
                      21
                    </p>
                    High Score{" "}
                    <p
                      className="green"
                      style={{ fontSize: "26px", paddingLeft: "52px" }}
                    >
                      136
                    </p>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>

        <Row lg={12} className="justify-content-center">
          <Col lg={10} className="new-tble-bx">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th>Asset</th>
                  <th>Title</th>
                  <th>Pieces</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, i) => (
                  <tr key={i}>
                    <td>{item.orderID}</td>
                    <td>{item.title}</td>
                    <td>{item.pieces}</td>
                    <td>{item.quantity}</td>
                    <td>{item.action}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailsPhotographyView;
// checked
