import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EbayRepairing from "../../component/repairing/EbayRepairing";
import ToRepairing from "../../component/repairing/ToRepairing";
import Repaired from "../../component/repairing/Repaired";

const index = () => {
  return (
    <>
      <Container fluid>
        <Row lg={12}>
          <Col lg={3}>
            <EbayRepairing />
          </Col>
          <Col lg={6}>
            <ToRepairing />
          </Col>
          <Col lg={3}>
            <Repaired />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default index;
// checked
