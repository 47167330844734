import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EbayPhotography from "../../component/photography/EbayPhotography";
import ToPhotography from "../../component/photography/ToPhotography";
import Photographed from "../../component/photography/Photographed";

const index = () => {
  return (
    <>
      <Container fluid>
        <Row lg={12}>
          <Col lg={3}>
            <EbayPhotography />
          </Col>
          <Col lg={6}>
            <ToPhotography />
          </Col>
          <Col lg={3}>
            <Photographed />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default index;
// checked
