import React from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const ShippedToday = (props) => {
  return (
    <Card className="endingStyle">
      <Container>
        <div style={{ position: "relative" }}>
          <span
            className="header"
            style={{
              position: "absolute",
              display: "inline-block",
              left: "14px",
            }}
          >
            {props.title}
          </span>
        </div>
        <Row>
          <Col style={{ paddingTop: "38px" }}>
            <p style={{ textAlign: "center", fontSize: 14 }}>Today </p>
            <p
              className={props.title === "Ending Soon" ? "green" : "red"}
              style={{ fontSize: 22 }}
            >
              {props.value.today}
            </p>
          </Col>
          <Col style={{ paddingTop: "38px" }}>
            <p style={{ textAlign: "center", fontSize: 14 }}>Yesterday </p>
            <p className="green" style={{ fontSize: 22 }}>
              {props.value?.yesterday}
            </p>
          </Col>
          <Col>
            Ground
            <p className="green" style={{ fontSize: "22px" }}>
              {props.value.ground}
            </p>
            Freight{" "}
            <p className="green" style={{ fontSize: "22px" }}>
              {props.value.freight}
            </p>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default ShippedToday;
// checked
