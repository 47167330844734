import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EbayCleaning from "../../component/cleaning/EbayCleaning";
import Cleeaned from "../../component/cleaning/Cleeaned";
import ToShipCleaning from "../../component/cleaning/ToShipCleaning";
// import Ebay from '../../component/shipping/Ebay'
// import ToShip from '../../component/shipping/ToShip'
// import Shipped from '../../component/shipping/Shipped'

const index = () => {
  return (
    <>
      <Container fluid>
        <Row lg={12}>
          <Col lg={3}>
            <EbayCleaning />
          </Col>
          <Col lg={6}>
            <ToShipCleaning />
          </Col>
          <Col lg={3}>
            <Cleeaned />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default index;
