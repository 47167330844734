import React from "react";
import CleaningAverageValues from "./sub-components/CleaningAverageValues";
import { Card, Col, Container, Row } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import CleaningStatusCard from "./sub-components/CleaningStatusCard";

const Cleeaned = (props) => {
  const shippedValue = {
    freight: "6",
    orders: "126",
    ground: "144",
    // freight: "8"
  };
  const chartData = {
    series: [600, 300],

    options: {
      chart: { type: "donut", foreColor: "#ffffff", height: 412 },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: { colors: ["#e8eb34", "#ff5156"] },
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },

      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,
                formatter: function (w) {
                  //   const totals = w.globals.seriesTotals;
                  // let myNums = [1, 2, 3, 4, 5];

                  // create a variable for the sum and initialize it
                  let sum = 0;

                  // iterate over each item in the array
                  for (let i = 0; i < w.globals.seriesTotals.length; i++) {
                    sum += w.globals.seriesTotals[i];
                  }
                  // console.log(w.globals.seriesTotals);
                  //   const result = totals.reduce((a, b) => a + b, 0);

                  return sum;
                  // return w.globals.seriesTotals;
                },
              },
            },
          },
        },
      },
    },
  };
  const eBayValue = {
    today: "168",
    yesterday: "67",
    ground: "150",
    freight: "8",
  };
  return (
    <div>
      <p className="secondHeader">Cleaned</p>
      <CleaningAverageValues value={shippedValue} />

      <Card className="endingStyle">
        <Container>
          <Row>
            <Col style={{ paddingTop: "48px" }}>
              <p style={{ textAlign: "center" }}>High Score </p>
              <p
                className="green"
                style={{
                  fontSize: "22px",
                }}
              >
                86
              </p>
            </Col>

            <Col style={{ paddingTop: "0px" }}>
              <p style={{ textAlign: "center", fontSize: "22px" }}>
                {" "}
                Shipped Today{" "}
              </p>

              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="donut"
                height={150}
                width={150}
              />
            </Col>
            <Col style={{ paddingTop: "48px" }}>
              <p style={{ textAlign: "center" }}>Daily Average </p>
              <p
                className="green"
                style={{
                  fontSize: "22px",
                }}
              >
                56
              </p>
            </Col>
          </Row>
        </Container>
      </Card>
      <CleaningStatusCard title={"Shipped"} value={eBayValue} />
      <Card className="endingStyle">
        <Container>
          <Row>
            <Col style={{ paddingTop: "38px" }}>
              <p style={{ textAlign: "center" }}>
                Daily Average{" "}
                <p>
                  Orders:{" "}
                  <span style={{ display: "inline-block", color: "#588b4c" }}>
                    52
                  </span>
                </p>{" "}
              </p>

              <p style={{ textAlign: "center" }}>
                Weekly Average{" "}
                <p>
                  Orders:{" "}
                  <span style={{ display: "inline-block", color: "#588b4c" }}>
                    135
                  </span>
                </p>{" "}
              </p>

              <p style={{ textAlign: "center" }}>
                Monthly Average{" "}
                <p>
                  Orders:{" "}
                  <span style={{ display: "inline-block", color: "#588b4c" }}>
                    488
                  </span>
                </p>{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  );
};

export default Cleeaned;
// checked
