import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Table from "react-bootstrap/Table";
import ReactApexChart from "react-apexcharts";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetTestingingDetails } from "../../hooks/useTesting";
import {
  LeftSkeleton,
  MiddleSkeleton,
  RightSkeleton,
  TableSkeleton,
} from "../../component/testing/sub-components/skeleton";

const TestingDetailsView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { data: allDetails, isLoading: isAllDetailsLoading } =
    useGetTestingingDetails({
      uid: state.uid,
    });
  // const chartData = {
  //   series: [2, 7],

  //   options: {
  //     chart: { type: "donut", foreColor: "#ffffff", height: 412 },
  //     legend: { show: false },
  //     dataLabels: { enabled: false },
  //     tooltip: { enabled: false },
  //     fill: { colors: ["#e8eb34", "#ff5156"] },
  //     states: {
  //       hover: { filter: { type: "none", value: 0 } },
  //       active: { filter: { type: "none", value: 0 } },
  //     },
  //     stroke: { width: 0 },

  //     plotOptions: {
  //       pie: {
  //         expandOnClick: false,
  //         donut: {
  //           size: "60%",
  //           labels: {
  //             show: true,
  //             name: { show: false },
  //             total: {
  //               show: true,
  //               showAlways: true,

  //               formatter: function (w) {
  //                 // let sum = 0;

  //                 // for (let i = 0; i < w.globals.seriesTotals.length; i++) {
  //                 //   sum += w.globals.seriesTotals[i];
  //                 // }
  //                 // //   console.log('%c Sum: ' + sum, 'color: green; font-weight: bold;');

  //                 return w.globals.seriesTotals[0] || 0;
  //                 // return sum
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  // };
  const chartData2 = {
    series: [600],

    options: {
      chart: {
        type: "donut",
        foreColor: "#ffffff",
        height: 300,
        width: 300,
      },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: { colors: [allDetails?.user?.colorSelector || "#7FD320"] },
      states: {
        hover: { filter: { type: "none", value: 0 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,
                formatter: function (w) {
                  //   const totals = w.globals.seriesTotals;

                  //   const result = totals.reduce((a, b) => a + b, 0);

                  //   return (result / 1000).toFixed(3);
                  return w.globals.seriesTotals;
                },
              },
            },
          },
        },
      },
    },
  };

  return (
    <>
      <Container fluid>
        <i onClick={() => navigate(-1)} className="fa-solid fa-arrow-left" />
        <p className="secondHeader">Testing</p>

        <Row lg={8} className="justify-content-center">
          <Col lg={3}>
            {isAllDetailsLoading ? (
              <LeftSkeleton />
            ) : (
              <Card className="endingStyle">
                <Container>
                  <Row>
                    <Col style={{ paddingTop: "38px" }}>
                      <h5 style={{ textAlign: "center", marginBottom: 5 }}>
                        Today
                      </h5>

                      <p>
                        Assets:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.ast_count}
                        </span>
                      </p>
                      <h5
                        style={{
                          textAlign: "center",
                          marginBottom: 5,
                          marginTop: 10,
                        }}
                      >
                        Average time
                      </h5>
                      <p>
                        Spent:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {/* {parseFloat(allDetails?.user?.time / 60).toFixed(2)}{" "} */}
                          {parseFloat(
                            allDetails?.user?.dailyAverageTime / 60
                          ).toFixed(2)}{" "}
                          Minutes
                        </span>
                      </p>

                      <p style={{ marginTop: 24, marginBottom: 24 }}>
                        Total Quantity:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.qty}
                        </span>
                      </p>

                      <h5
                        style={{
                          textAlign: "center",
                          color: "yellow",
                          marginBottom: 18,
                        }}
                      >
                        Action
                      </h5>
                      <p style={{ color: "white" }}>
                        eBay:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.ebay_count}
                        </span>
                      </p>

                      <p style={{ color: "white" }}>
                        GovDeals:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.gov_count}
                        </span>
                      </p>
                      <p>
                        HiBid:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          0
                        </span>
                      </p>
                      <p>
                        Restricted:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.restricted_count}
                        </span>
                      </p>
                      <p>
                        Negotiated:{" "}
                        <span
                          style={{
                            display: "inline-block",
                            color: "yellowgreen",
                          }}
                        >
                          {allDetails?.user?.negotiation_count}
                        </span>
                      </p>
                    </Col>
                  </Row>
                </Container>
              </Card>
            )}
          </Col>
          <Col lg={4}>
            {isAllDetailsLoading ? (
              <MiddleSkeleton />
            ) : (
              <Card className="endingStyle">
                <h4>
                  {" "}
                  {allDetails?.user?.firstName +
                    " " +
                    allDetails?.user?.lastName}
                </h4>
                <Container>
                  <Card.Body>
                    <Card.Title>Today</Card.Title>
                    <ReactApexChart
                      options={chartData2.options}
                      // series={[
                      //   allDetails?.user?.count,
                      //   allDetails?.user?.count >= 100 ? 0 : 100,
                      // ]
                      series={
                        allDetails?.user?.count
                          ? [allDetails?.user?.count]
                          : [600]
                      }
                      type="donut"
                      height={300}
                    />
                  </Card.Body>
                </Container>
              </Card>
            )}
          </Col>
          <Col lg={3}>
            {" "}
            {isAllDetailsLoading ? (
              <RightSkeleton />
            ) : (
              <Card className="endingStyle">
                <Container>
                  <Row>
                    <Col>
                      Daily Average
                      <p
                        className="green"
                        style={{ fontSize: "26px", paddingLeft: "58px" }}
                      >
                        {allDetails?.user?.average}
                      </p>
                      High Score{" "}
                      <p
                        className="green"
                        style={{ fontSize: "26px", paddingLeft: "44px" }}
                      >
                        {allDetails?.user?.highScore}
                      </p>
                      Daily Average Time Spent{" "}
                      <p className="green" style={{ fontSize: "21px" }}>
                        {parseFloat(
                          allDetails?.user?.dailyAverageTime / 60
                        ).toFixed(2)}{" "}
                        Minutes
                      </p>
                      Weekly Average Time Spent{" "}
                      <p
                        className="green"
                        style={{
                          fontSize: "21px",
                          // color: "yellow"
                        }}
                      >
                        {parseFloat(
                          allDetails?.user?.weeklyAverageTime / 60
                        ).toFixed(2)}{" "}
                        Minutes
                      </p>
                      Monthly Average Time Spent{" "}
                      <p
                        className="green"
                        // className="red"
                        style={{ fontSize: "21px" }}
                      >
                        {parseFloat(
                          allDetails?.user?.monthlyAverageTime / 60
                        ).toFixed(2)}{" "}
                        Minutes
                      </p>
                    </Col>
                  </Row>
                </Container>
              </Card>
            )}
          </Col>
        </Row>

        <Row lg={12} className="justify-content-center">
          {isAllDetailsLoading ? (
            <TableSkeleton />
          ) : (
            <Col lg={11} className="new-tble-bx">
              <Table responsive striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Asset</th>
                    <th>Title</th>
                    <th>Pieces</th>
                    {/* <th>Client</th> */}
                    <th>Quantity</th>
                    <th>Value</th>
                    <th>Time Spent</th>
                    <th>Action</th>
                    <th>P.O</th>
                  </tr>
                </thead>
                <tbody>
                  {allDetails?.assets.map((item, i) => (
                    <tr key={i}>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(
                            `https://www.listapp.info/admin/asset_no_to_asset_id.php?assetno=${item?.assetNo}`,
                            "_blank"
                          )
                        }
                      >
                        {item?.assetNo}
                      </td>
                      <td>{item?.title}</td>
                      <td>{item?.pieces}</td>

                      {/* <td>{item?.client}</td> */}
                      <td>{item?.qty}</td>
                      <td
                        style={{
                          color:
                            item?.value === 1
                              ? "#FD0000"
                              : item?.value === 2
                              ? "#F58021"
                              : item?.value === 3
                              ? "#00A82D"
                              : item?.value === 4
                              ? "rgb(255, 180, 196)"
                              : item?.value === 5
                              ? "#D767FF"
                              : "000",
                        }}
                      >
                        {item?.value === 1
                          ? "LOW ($1 - $200)"
                          : item?.value === 2
                          ? "MED ($201 - $500)"
                          : item?.value === 3
                          ? "HIGH ($501 - $2000)"
                          : item?.value === 4
                          ? "GOLD ($2001 - $5000)"
                          : item?.value === 5
                          ? "DIAMOND ($5001+)"
                          : ""}
                      </td>
                      <td>
                        {parseFloat(item?.timeSpent / 60).toFixed(2)} Minutes
                        {/* {item?.timeSpent || "0.00"} Minutes */}
                      </td>
                      <td>{item?.action}</td>
                      <td>{item?.poId || "--"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default TestingDetailsView;
// checked
