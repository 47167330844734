import React from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const EndingDueValues = (props) => {
  return (
    <Card className="endingPayment">
      <Container style={{ paddingRight: "38px" }}>
        {/* <div style={{ position: "relative" }}>
              <span
                className="header"
                style={{
                  position: "absolute",
                  display: "inline-block",
                  left: "15px",
                }}
              >
                {props.title}
              </span>
            </div> */}
        <Row>
          <Col style={{ paddingTop: "18px" }}>
            <p style={{ textAlign: "center", color: "yellow" }}> Due Today </p>
            <p className="green" style={{ fontSize: "32px" }}>
              {props.value.due_today}
            </p>
          </Col>
          <Col style={{ paddingTop: "15px" }}>
            <p style={{ textAlign: "center", color: "red", fontSize: "22px" }}>
              {" "}
              Overdue{" "}
            </p>
            <p className="red" style={{ fontSize: "32px" }}>
              {props.value?.overdue
                ? props.value?.overdue
                : props.value?.due_tomorrow}
            </p>
          </Col>
          <Col style={{ paddingTop: "18px" }}>
            <p style={{ textAlign: "center", color: "yellowgreen" }}>
              {" "}
              Due Tomorrow{" "}
            </p>
            <p className="green" style={{ fontSize: "32px" }}>
              {props.value?.due_tomorrow
                ? props.value?.due_tomorrow
                : props.value?.overdue}
            </p>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default EndingDueValues;
// checked
