import React from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const EndingCards = (props) => {
  return (
    <Card className="endingStyle ">
      <Container>
        <div style={{ position: "relative" }}>
          <span
            className="header"
            style={{
              position: "absolute",
              display: "inline-block",
              left: "15px",
            }}
          >
            {/* {props.title} */}
          </span>
        </div>
        <Row>
          {/* <Col style={{ paddingTop: "38px" }}>
            <p style={{ textAlign: "center" }}>Today </p>
            <p
              className={props.title === "Ending Soon" ? "green" : "red"}
              style={{ fontSize: "32px" }}
            >
              {props.value.today}
            </p>
          </Col>
          <Col style={{ paddingTop: "38px" }}>
            {props.value?.yesterday ? <p>Yesterday </p> : <p>Tommorow </p>}
            <p className="green" style={{ fontSize: "32px" }}>
              {props.value?.yesterday
                ? props.value?.yesterday
                : props.value?.tomorrow}
            </p>
          </Col> */}
          <Col>
            Ground
            <p className="green" style={{ fontSize: "26px" }}>
              {props.value.ground}
            </p>
          </Col>{" "}
          <Col>
            Freight{" "}
            <p className="green" style={{ fontSize: "26px" }}>
              {props.value.freight}
            </p>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default EndingCards;
// checked
