import React from "react";

import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import DueCardValues from "./sub-components/DueCardValues";
import { DueCardsSkeleton } from "./sub-components/skeleton";
const DueCards = ({
  dueData,
  isDueDataLoading,
  pickedData,
  isPickedDataLoading,
}) => {
  return (
    <div>
      {isDueDataLoading ? (
        <DueCardsSkeleton />
      ) : (
        <DueCardValues dueData={dueData} />
      )}

      <Card className="endingPayment" style={{ height: 140 }}>
        <Container>
          <Row>
            <Col style={{ paddingTop: "14px" }}>
              <p style={{ textAlign: "center", color: "white" }}>
                Items Shelved{" "}
              </p>
              <p
                className="green"
                style={{ textAlign: "center", fontSize: "24px" }}
              >
                {pickedData?.reduce((count, item) => {
                  return item.type === "shelving" ? count + 1 : count;
                }, 0) || 0}
              </p>
            </Col>
            <Col style={{ paddingTop: "14px" }}>
              <p style={{ textAlign: "center", color: "white" }}>
                Items Picked{" "}
              </p>
              <p
                className="green"
                style={{ textAlign: "center", fontSize: "24px" }}
              >
                {pickedData?.reduce((count, item) => {
                  return item.type === "picked" ? count + 1 : count;
                }, 0) || 0}
              </p>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  );
};

export default DueCards;
