import React from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const employeeListData = [
    {
      userName: "Becky Apolinar",
      frieght: "8765",
      ground: "5678",
      total: "765456",
    },
    {
      userName: "Becky Apolinar",
      frieght: "8765",
      ground: "5678",
      total: "765456",
    },
    {
      userName: "Becky Apolinar",
      frieght: "8765",
      ground: "5678",
      total: "765456",
    },
    {
      userName: "Becky Apolinar",
      frieght: "8765",
      ground: "5678",
      total: "765456",
    },
    {
      userName: "Becky Apolinar",
      frieght: "8765",
      ground: "5678",
      total: "765456",
    },
  ];
const AllEmployeeListCleaning = ({setShowTable}) => {
    const navigate = useNavigate();
  return (
    <>
      <Container fluid>
        <p className="secondHeader">All Cleaning Employee List</p>
        <Row lg={12} className="justify-content-center">
          <Col lg={10} className="new-tble-bx">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Frieght</th>
                  <th>Ground</th>
                  <th>Total</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {employeeListData.map((item, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item.userName}</td>
                    <td>{item.frieght}</td>
                    <td>{item.ground}</td>
                    <td>{item.total}</td>
                    <td
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/cleaning/employee-list");
                      }}
                    >
                      View details
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <span
          className="secondHeader"
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setShowTable(false);
          }}
        >
          Hide
        </span>
      </Container>
    </>
  )
}

export default AllEmployeeListCleaning