import React from "react";

import EndingShipped from "./sub-components/EndingShipped";

import ShippedToday from "../../utilites/ShippedToday";
import { Card, Col, Container, Row } from "react-bootstrap";

import ReactApexChart from "react-apexcharts";
import { AverageValuesSkeleton } from "../screening/sub-components/skeleton";
const Shipped = ({ allShippingList, isShippingLoading }) => {
  const chartData = {
    series: [600],

    options: {
      chart: { type: "donut", foreColor: "#ffffff", height: 412 },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: { colors: ["#e8eb34", "#ff5156"] },
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },

      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,
                formatter: function (w) {
                  //   const totals = w.globals.seriesTotals;
                  // let myNums = [1, 2, 3, 4, 5];

                  // create a variable for the sum and initialize it
                  let sum = 0;

                  // iterate over each item in the array
                  for (let i = 0; i < w.globals.seriesTotals.length; i++) {
                    sum += w.globals.seriesTotals[i];
                  }
                  // console.log(w.globals.seriesTotals);
                  //   const result = totals.reduce((a, b) => a + b, 0);

                  return sum;
                  // return w.globals.seriesTotals;
                },
              },
            },
          },
        },
      },
    },
  };
  return (
    <div>
      <p className="secondHeader">Shipped</p>

      {isShippingLoading ? (
        <>
          <AverageValuesSkeleton />
        </>
      ) : (
        <>
          <EndingShipped
            title={"Shipped"}
            totalFreightCount={allShippingList?.totalFreightCount || 0}
            totalGroundCount={allShippingList?.totalGroundCount || 0}
            orderCount={allShippingList?.totalCount || 0}
          />

          <Card className="endingStyle">
            <Container>
              <Row>
                <Col style={{ paddingTop: "48px" }}>
                  <p style={{ textAlign: "center", fontSize: 14 }}>
                    High Score{" "}
                  </p>
                  <p
                    className="green"
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    N/A
                  </p>
                </Col>

                <Col style={{ paddingTop: "0px" }}>
                  <p style={{ textAlign: "center", fontSize: 14 }}>
                    {" "}
                    Shipped Today{" "}
                  </p>

                  <ReactApexChart
                    options={chartData.options}
                    series={
                      allShippingList?.totalCount
                        ? [allShippingList?.totalCount]
                        : [0]
                    }
                    type="donut"
                    height={150}
                    width={150}
                  />
                </Col>
                <Col style={{ paddingTop: "48px" }}>
                  <p style={{ textAlign: "center", fontSize: 14 }}>
                    Daily Average{" "}
                  </p>
                  <p
                    className="green"
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    {allShippingList?.dailyAverage ?? 0}
                  </p>
                </Col>
              </Row>
            </Container>
          </Card>
          <ShippedToday
            title={"Shipped"}
            value={{
              today: allShippingList?.totalCount || 0,
              yesterday: allShippingList?.totalYesterdayCount || 0,
              ground: allShippingList?.totalGroundCount || 0,
              freight: allShippingList?.totalFreightCount || 0,
            }}
          />
          <Card className="endingStyle">
            <Container>
              <Row>
                <Col style={{ paddingTop: "38px" }}>
                  <p style={{ textAlign: "center", fontSize: 14 }}>
                    Daily Average{" "}
                    <p>
                      <span
                        style={{
                          display: "inline-block",
                          color: "#588b4c",
                          fontSize: 18,
                        }}
                      >
                        {allShippingList?.dailyAverage ?? 0} Orders
                      </span>
                    </p>{" "}
                  </p>

                  <p style={{ textAlign: "center", fontSize: 14 }}>
                    Weekly Average{" "}
                    <p>
                      <span
                        style={{
                          display: "inline-block",
                          color: "#588b4c",
                          fontSize: 18,
                        }}
                      >
                        {allShippingList?.weeklyAverage ?? 0} Orders
                      </span>
                    </p>{" "}
                  </p>

                  <p style={{ textAlign: "center", fontSize: 14 }}>
                    Monthly Average{" "}
                    <p>
                      <span
                        style={{
                          display: "inline-block",
                          color: "#588b4c",
                          fontSize: 18,
                        }}
                      >
                        {allShippingList?.monthlyAverage ?? 0} Orders
                      </span>
                    </p>{" "}
                  </p>
                </Col>
              </Row>
            </Container>
          </Card>
        </>
      )}
    </div>
  );
};

export default Shipped;
