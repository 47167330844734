import React from "react";
import Card from "react-bootstrap/Card";
import "../../../utilites/Card.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const EndingShipped = ({ totalFreightCount, totalGroundCount, orderCount }) => {
  return (
    <Card className="endingPayment">
      <Container style={{ paddingRight: "22px" }}>
        <Row>
          <Col style={{ paddingTop: "6px" }}>
            <p
              style={{
                textAlign: "center",
                color: "white",
                fontSize: 14,
                marginBottom: 0,
              }}
            >
              Freight
            </p>
            <p className="green" style={{ fontSize: 22 }}>
              {totalFreightCount}
            </p>
          </Col>

          <Col style={{ paddingTop: "6px" }}>
            <p
              style={{
                textAlign: "center",
                color: "white",
                fontSize: 14,
                marginBottom: 0,
              }}
            >
              Orders
            </p>
            <p className="green" style={{ fontSize: 22 }}>
              {orderCount ?? 0}
            </p>
          </Col>

          <Col style={{ paddingTop: "6px" }}>
            <p
              style={{
                textAlign: "center",
                color: "white",
                fontSize: 14,
                marginBottom: 0,
              }}
            >
              Ground
            </p>
            <p className="green" style={{ fontSize: 22 }}>
              {totalGroundCount}
            </p>
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default EndingShipped;
