import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, Container, Row, Table } from "react-bootstrap";

const tableData = [
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    value: "$230",
    time_spent: "1 Minute",
    action: "eBay",
  },
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    value: "$230",
    time_spent: "1 Minute",
    action: "eBay",
  },
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    value: "$230",
    time_spent: "1 Minute",
    action: "eBay",
  },
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    value: "$230",
    time_spent: "1 Minute",
    action: "eBay",
  },
  {
    orderID: "ORD-8765",
    title: "Lorem Ipsum ",
    pieces: "1",
    quantity: "20",
    value: "$230",
    time_spent: "1 Minute",
    action: "eBay",
  },
];
const DetailsRepairView = () => {
  const chartData = {
    series: [2, 7],

    options: {
      chart: { type: "donut", foreColor: "#ffffff", height: 412 },
      legend: { show: false },
      dataLabels: { enabled: false },
      tooltip: { enabled: false },
      fill: { colors: ["#e8eb34", "#ff5156"] },
      states: {
        hover: { filter: { type: "lighten", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },

      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: { show: false },
              total: {
                show: true,
                showAlways: true,
                
                formatter: function (w) {
                  let sum = 0;

                  for (let i = 0; i < w.globals.seriesTotals.length; i++) {
                    sum += w.globals.seriesTotals[i];
                  }
                //   console.log('%c Sum: ' + sum, 'color: green; font-weight: bold;');

                  return sum;
                },
              },
            },
          },
        },
      },
    },
  };
  return (
    <>
      <Container fluid>
        <p className="secondHeader">Repair</p>

        <Row lg={8} className="justify-content-center">
          <Col lg={3}>
            <Card className="endingStyle">
              <Container>
              <Row>
                  <Col style={{ paddingTop: "38px" }}>
                    <p style={{ textAlign: "center" }}>Today </p>

                    <p>
                      Shipped:{" "}
                      <span style={{ display: "inline-block",color:'yellowgreen' }}>22</span>
                    </p>

                    <p style={{ textAlign: "center" }}>Average Time <p>
                      Spent:{" "}
                      <span style={{ display: "inline-block",color:'yellowgreen' }}>4 Minutes</span>
                    </p> </p>

                   

                    <p>
                      Total Quantity:{" "}
                      <span style={{ display: "inline-block",color:'yellowgreen' }}>45</span>
                    </p>

                    <p style={{ textAlign: "center", color: "yellow" }}>
                      Action<p style={{color:'white'}}>
                      eBay <span style={{ display: "inline-block",color:'yellowgreen' }}>:22</span>
                    </p>
                   
                    </p>
                    
                    <p>
                      POS<span style={{ display: "inline-block",color:'yellowgreen' }}>:0</span>
                    </p>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="endingStyle">
              <h4>Dan Johson</h4>
              <Container>
                <Card.Body>
                  <Card.Title>Today</Card.Title>
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    height={300}
                    type="donut"
                
                  />
                </Card.Body>
              </Container>
            </Card>
          </Col>
          <Col lg={3}>
            {" "}
            <Card className="endingStyle">
              <Container>
                <Row>
                  <Col>
                    Daily Average
                    <p
                      className="green"
                      style={{ fontSize: "26px", paddingLeft: "58px" }}
                    >
                      21
                    </p>
                    High Score{" "}
                    <p
                      className="green"
                      style={{ fontSize: "26px", paddingLeft: "44px" }}
                    >
                      136
                    </p>
                    Daily Average Shipped{" "}
                    <p className="green" style={{ fontSize: "21px" }}>
                      35 Orders
                    </p>
                    Weekly Average Shipped{" "}
                    <p style={{ fontSize: "21px", color: "yellow" }}>
                      28 Orders
                    </p>
                    Monthly Average Shipped{" "}
                    <p className="red" style={{ fontSize: "21px" }}>
                      22 Orders
                    </p>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>

        <Row lg={12} className="justify-content-center">
          <Col lg={10} className="new-tble-bx">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  {/* <th>#</th> */}
                  <th>Asset</th>
                  <th>Title</th>
                  <th>Pieces</th>
                  <th>Quantity</th>
                  <th>Value</th>
                  <th>Time Spent</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item, i) => (
                  <tr key={i}>
                    <td>{item.orderID}</td>
                    <td>{item.title}</td>
                    <td>{item.pieces}</td>
                    <td>{item.quantity}</td>
                    <td>{item.value}</td>
                    <td>{item.time_spent}</td>
                    <td>{item.action}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailsRepairView;
